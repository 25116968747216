import React from "react";
import Header from "../components/Header";
import RecruitmentBlurb from "../components/RecruitmentBlurb";
// function Recruitment() {
//   return (
//     <>
const Recruitment = () => {
  return (
    <>
      <Header underline={"recruitment"} />
      <img
        className="absolute opacity-[0.40] -z-50 h-[66vh] w-full top-0 object-cover"
        // className="absolute opacity-[0.85] -z-50 h-[66vh] w-full top-0 object-cover"
        // src={require("../images/ABOUTUS_CROP.jpg")}
        // src={require("../images/hospitalStaff.jpg")}
        // src={require("../images/recruitment1.jpg")}
        // src={require("../images/recruitment2.png")}
        src={require("../images/recruitment3COLD.png")}
        alt=""
      />
      <div className="pt-14 text-3xl">RECRUITMENT SERVICES</div>
      {/* <div className="pt-28 text-3xl">RECRUITMENT SERVICES</div> */}

      <RecruitmentBlurb />

    </>
  );

};

{/* <div className="grid grid-flow-col  grid-cols-2 grid-cols-flow  gap-y-8 gap-x-32 bg-white text-green py-20 text-justify xl:text-xl lg:px-20 px-8 text-lg"> */ }
{/* <div className="grid grid-flow grid-cols-1 sm:grid-cols-2 grid-cols-flow gap-y-8 gap-x-32 bg-white text-green py-20 text-justify xl:text-xl lg:px-20 px-8 text-lg"> */ }
{/* <div className="grid grid-flow-col grid-cols-2 flex flex-wrap  gap-y-8 gap-x-32 bg-white text-green py-20 text-justify xl:text-xl lg:px-20 px-8 text-lg"> */ }
{/* <div className="col-start-1 col-end-2">
          At St Agatha Care, we understand how challenging and isolating it can
          be when your loved ones need additional support in their lives.
          <br />
          <br />
          We assist you by offering personal care in the privacy of your
          own home as an affordable alternative to private residential care. We believe
          people are happier and healthier when they are able to remain living in the
          familiar surroundings of their home, despite health challenges or old age.
          <br />
          <br />
          Maintaining confidentiality and building a meaningful, long-term relationship
          with the same carer are important to us. Meeting physical, emotional and spiritual needs
          beyond mere functional care is at the core of St Agatha Care's values.
          <br />
          <br />
          We take time to understand the
          client's requirements in order to find the care specialist that best meets
          their specific needs.
        </div>
        <div>
          The assigned carers spend time getting to know the individuals,
          their routines and preferences, tailoring services to their needs, assisting them
          with dignity and respect as well as promoting independence.
          <br />
          <br />
          Once we have found a suitable carer for you, you will be in full control of the
          service. We will, of course, remain by your side if you have any questions or if
          you need us to find another carer should your main, dedicated carer be ill or absent
          for any other reason.
          <br />
          <br />
          Each carer undergoes a rigorous interview process, including relevant
          checks such as DBS, qualifications, experience, right to work in the
          UK and professional references. After these thorough assessments, each
          carer we put forward is selected for their kindness, politeness,
          respectfulness and natural, caring human touch.
        </div> */}

{/* </div> */ }

{/* </>
  );
} */}

export default Recruitment;
