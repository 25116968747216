import React from "react";

const RecruitmentBrief = () => {
  return (
    <div className="sm:grid grid-cols-2 lg:gap-12 gap-8 lg:p-12 p-8 bg-green">
      <img
        className="sm:pb-0 pb-6 sm:hidden"
        src={require("../images/CAREGIVER5.jpg")}
        // src={require("../images/CAREGIVER3.jpg")}
        // src={require("../images/CAREGIVER4WARM.jpg")}
        alt=""
      />
      <div className="grid grid-flow-row text-start gap-2 text-white">
        <div className="text-3xl">RECRUITMENT SERVICES</div>
        <div className="text-xl">
          St Agatha Care is a boutique recruitment agency that provides
          trained Health and Care Assistants to hospitals, residential
          care homes, supported living environments and private individuals. Supported
          by an extensive network of experienced care professionals, we can
          deliver exceptional staff when you need them.

          {/* Based in London, St Agatha Care is a boutique recruitment agency that provides
          trained Healthcare Assistants (HCA) and support workers to private hospitals, residential
          care homes, supported living environments and private individuals. */}

          {/* St Agatha Care is built upon strong family values.
          Our services are designed to give our clients flexibility and choice of care,
          as an affordable alternative to residential care. */}

          {/* Respectful, compassionate, reliable care and support comes in many forms.
          Our services are designed to give our clients flexibility and choice of care
          within the privacy of their own home. */}
        </div>
        <button className="w-40 h-16 bg-white text-[#48596c]"
          onClick={(e) => {
            e.preventDefault();
            // window.location.href = "/services";
            // window.location.href = "/Values"
            window.location.href = "/Recruitment"
          }}

        >
          Learn more
        </button>
      </div>

      <img
        className="sm:pb-0 pb-6 hidden sm:block"
        // src={require("../images/CAREGIVER4WARM.jpg")}
        src={require("../images/CAREGIVER5.jpg")}
        // src={require("../images/OUTDOORS_CROP2.jpeg")}
        alt=""
      />
    </div>
  );
};
export default RecruitmentBrief;
