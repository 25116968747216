import React, { useState } from "react";
import Hamburger from "./Hamburger";

const Header = ({ underline }) => {
  return (
    <>
      <Hamburger />
      <div className="grid grid-cols-2 px-8 pt-8">
        <img
          className="w-[225px]"
          //Previous logo below was with w-[350px]
          //src={require("../images/STAC_LOGO_HORIZONTAL_WHITE.png")}
          src={require("../images/STAC_LOGO_WHITE.png")}
          alt=""
        />
        <div className="hidden xl:text-xl xl:pr-8 lg:gap-8 md:grid grid-flow-col gap-4 text-lg items-center justify-end">
          <a
            href="/"
            className={`${
              underline === "home" ? "underline underline-offset-8" : ""
            }`}
          >
            Home
          </a>
          <a
            href="/recruitment"
            className={`${
              underline === "recruitment" ? "underline underline-offset-8" : ""
            }`}
          >
            {/* Values */}
            Recruitment Services
          </a>
          <a
            href="/services"
            className={`${
              underline === "services" ? "underline underline-offset-8" : ""
            }`}
          >
            {/* Services */}
            {/* At Home Services */}
            {/* Services at Home */}
            {/* Home Services */}
            Home Care
          </a>
          <a
            href="/Values"
            className={`${
              underline === "values" ? "underline underline-offset-8" : ""
            }`}
          >
            {/* Team */}
            Values
          </a>
          <a
            href="/team"
            className={`${
              underline === "team" ? "underline underline-offset-8" : ""
            }`}
          >
            {/* Team */}
            About
          </a>
          <a
            href="/contact"
            className={`${
              underline === "contact" ? "underline underline-offset-8" : ""
            }`}
          >
            Contact
          </a>
        </div>
      </div>
      <div className="grid grid-rows-2 pl-8 pt-4 text-xl">
        {/* <div className="text-start">enquiries@stagathacare.co.uk</div> */}

        <div className="text-start text-2xl">0207 884 0545</div>
        <div className="text-start text-l">enquiries@stagathacare.co.uk</div>
      </div>
    </>
  );
};
export default Header;
