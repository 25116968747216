import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Recruitment from "./pages/Recruitment";
import Values from "./pages/Values";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import VisitingCareDetail from "./pages/VisitingCareDetail";
import LiveInCareDetail from "./pages/LiveInCareDetail";
//import OurServicesBlurb from "./pages/OurServicesBlurb";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <div className="App">
        {/* <Header /> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<Team />} />
            <Route path="/recruitment" element={<Recruitment />} />
            <Route path="/values" element={<Values />} />
            <Route path="/VisitingCareDetail" element={<VisitingCareDetail />} />
            <Route path="/LiveInCareDetail" element={<LiveInCareDetail />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default App;
