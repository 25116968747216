import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Hamburger = () => {
  const [menuActive, SetMenuActive] = useState(false);

  return (
    <>
      {menuActive ? (
        <div className="fixed h-full w-full bg-green">
          <button
            className="absolute right-8 top-8"
            onClick={() => SetMenuActive(!menuActive)}
          >
            <AiOutlineClose className="text-4xl" />
          </button>
          <div className="grid grid-rows-6 gap-12 p-12">
            <a href="/" className="underline underline-offset-4">
              Home
            </a>
            <a href="/recruitment" className="underline underline-offset-4">
              Recruitment Services
            </a>
            <a href="/services" className="underline underline-offset-4">
              Home Care
            </a>
            <a href="/values" className="underline underline-offset-4">
              Values
            </a>
            <a href="/team" className="underline underline-offset-4">
              About
            </a>
            <a href="/contact" className="underline underline-offset-4">
              Contact
            </a>
          </div>
        </div>
      ) : (
        <button
          className="md:hidden absolute right-8 top-8"
          onClick={() => SetMenuActive(!menuActive)}
        >
          <AiOutlineMenu className="text-4xl" />
        </button>
      )}
      {/* //{" "}
      <button className="absolute right-8">
        // <AiOutlineMenu className="text-4xl" />
        //{" "}
      </button>
      //{" "}
      <div className="fixed h-screen bg-green w-screen">
        //{" "}
        <div className="">
          // <AiOutlineClose className="text-4xl right-8" />
          //{" "}
        </div>
        //{" "}
        <div className="grid grid-rows-5 gap-6 text-2xl my-[60%]">
          //{" "}
          <a href="/" className="underline underline-offset-4">
            // Home //{" "}
          </a>
          //{" "}
          <a href="/values" className="underline underline-offset-4">
            // Services //{" "}
          </a>
          //{" "}
          <a href="/services" className="underline underline-offset-4">
            // Care //{" "}
          </a>
          //{" "}
          <a href="/team" className="underline underline-offset-4">
            // Values //{" "}
          </a>
          //{" "}
          <a href="/contact" className="underline underline-offset-4">
            // Contact //{" "}
          </a>
          //{" "}
        </div>
        //{" "}
      </div> */}
    </>
  );
};
export default Hamburger;
