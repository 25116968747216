import React from "react";

const Care1 = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 lg:p-12 p-8 lg:gap-12 gap-8">
      <img src={require("../images/DAYTIMECAREWARM.jpeg")} alt="" />
      {/* <img src={require("../images/DAYTIMECARE.jpeg")} alt="" /> */}
      <img
        className="md:block hidden"
        // src={require("../images/liveinWARM.jpg")}
        src={require("../images/LIVEIN_CROP2.jpeg")}

        alt=""
      />
      <div className="grid grid-flow-row gap-6 text-start">
        <div className="text-2xl">VISITING CARE</div>
        <div className="text-lg">
          St Agatha Care offers different levels of personalised care and a
          variety of services to match individual requirements in the privacy of
          your home, which can be provided on an hourly basis.
        </div>
        <div className="text-lg">
          Some clients may only need limited assistance, while others may
          require a higher degree of help during their daily activities to
          ensure they continue to enjoy an excellent quality of life while
          remaining as independent as possible. Each visiting care package will
          be personalised to meet individual needs.
        </div>

        <button className="w-40 h-16 bg-white text-[#48596c]"
          onClick={(e) => {
            e.preventDefault();
            //window.location.href = "/about";
            window.location.href = "/VisitingCareDetail";
          }}
        >
          Learn more
        </button>
      </div>
      <img
        className="md:hidden"
        src={require("../images/LIVEIN_CROP2.jpeg")}
        // src={require("../images/LiveInWarm.jpg")}
        alt=""
      />
      <div className="grid grid-flow-row gap-6 text-start">
        <div className="text-2xl">LIVE-IN CARE</div>
        <div className="text-lg">
          Live-in care services are the combination of bespoke personal care,
          homemaking, and companionship services in the familiar surroundings of
          your home. Live-in care offers more flexibility than hourly care
          because there is no need to fit in activities within a fixed hourly
          schedule since the carer will live with you.
        </div>
        <div className="text-lg">
          By understanding your wishes and routines, your carefully selected
          carer will create a unique plan of support to provide assistance in
          any aspect of the daily routine, personalised to meet individual
          needs.
        </div>

        <button className="w-40 h-16 bg-white text-[#48596c]"
          onClick={(e) => {
            e.preventDefault();
            //window.location.href = "/about";
            window.location.href = "/LiveInCareDetail";
          }}
        >
          Learn more
        </button>

        {/* <button className="w-40 h-16 bg-white text-[#48596c]">
          Learn more
        </button> */}
      </div>
    </div>
  );
};
export default Care1;
