import React from "react";
// import ServicesBlurb from "../components/ServicesBlurb";
// import OurServicesBlurb from "../components/OurServicesBlurb";
import HomeBrief from "../components/HomeBrief";
import RecruitmentBrief from "../components/RecruitmentBrief";
import Header from "../components/Header";
import ValuesBlurbOLD from "../components/ValuesBlurbOLD";
import CareBlurb from "../components/CareBlurb";
import CarersTestimony from "../components/CarersTestimony";

const Home = () => {
  return (
    <div>
      <div className="h-screen">
        <Header underline={"home"} />
        <img
          className="absolute opacity-40 -z-50 h-screen w-full top-0 object-cover"
          // className="absolute opacity-90 -z-50 h-screen w-full top-0 object-cover"
          // src={require("../images/homehero.jpg")}
          src={require("../images/homehero.jpg")}
          // src={require("../images/AdobeStock_262488386_retouchedWARM.jpg")}
          alt=""
        />
        {/* <div className="grid grid-rows-2 gap-24 p-12 pb-32">
          <div className="sm:grid hidden grid-rows-2 gap-3 text-xl">
            <div className="text-end">Tel: 0207 884 0545</div>
            <div className="text-end">Email: enquiries@stagathacare.co.uk</div>
          </div> */}
        {/* <div className="text-3xl xl:text-4xl text-[#48596c] pt-24"> */}
        <div className="text-3xl xl:text-4xl text-[#48596c] pt-10">

          {/* <div className="text-3xl xl:text-4xl text-[#48596c] pt-24 lg:pt-24 md:pt-24 sm:pt-24"> */}
          {/* <div className="text-3xl xl:text-4xl text-[#48596c] pt-32 sm:pt-44"> */}
          {/*text-green text-[#48596c] */}
          {/* PROVIDING HAPPY LIVES THROUGH LOVING, DELICATE CARE AT HOME */}
          {/* <p className="w-fit bg-gray-200 m-auto">BESPOKE SENIOR CARE AT HOME</p> */}
          {/* <p className="w-fit m-auto">BESPOKE SENIOR CARE AT HOME</p> */}
          {/* <p className="w-fit  text-white m-auto mt-5 ">BESPOKE SENIOR CARE AT HOME</p> */}
          {/* <p className="w-fit  text-white m-auto">Providing assistance through loving, delicate care</p> */}
          <p className="w-fit  text-white m-auto">
            <br />
            {/* PROVIDING ASSITANCE THROUGH LOVING, DELICATE CARE</p> */}
            SENIOR CARE RECRUITMENT SOLUTIONS</p>
          {/* <br /> */}
          <p className="w-fit text-white m-auto">Providing trained, qualified, reliable carers
            across London</p>
          {/* SENIOR CARE RECRUITMENT SOLUTIONS</p> */}
          {/* SENIOR CARE RECRUITMENT SOLUTIONS</p> */}
          {/* <p className="w-fit bg-[#48596c] text-white m-auto">BESPOKE SENIOR CARE AT HOME</p> */}
          {/* <p className="w-fit bg-[#48596c] text-white m-auto">Providing assistance through loving, delicate care</p> */}
        </div>
        {/* <div className="sm:hidden grid grid-rows-2 gap-3 text-xl">
            <div className="text-center">0207 884 0545</div>
            <div className="text-center">enquiries@stagathacare.co.uk</div>
          </div>
        </div> */}
      </div>
      {/* <ServicesBlurb /> */}
      <RecruitmentBrief />
      <HomeBrief />
      {/* <OurServicesBlurb /> */}
      <CarersTestimony />
      {/* <CareBlurb />
      <AboutBlurb /> */}
    </div >
  );
};

export default Home;
