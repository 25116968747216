import React from "react";
import VisitingCareDetailBlurb from "../components/VisitingCareDetailBlurb";
//import Care2 from "../components/Care2";
import Header from "../components/Header";

const VisitingCareDetail = () => {
  return (
    <>
      <Header underline={"services"} />
      <div className="text-3xl pt-14">VISITING CARE: PERSONAL AND COMPANIONSHIP CARE</div>
      {/* <div className="text-3xl p-8">COMPANIONSHIP CARE</div> */}

      <VisitingCareDetailBlurb />

    </>
  );
};

export default VisitingCareDetail;
