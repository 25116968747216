import React from "react";

const Care2 = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 lg:p-12 p-8 lg:gap-12 gap-8 bg-white text-green">
      {/* <img src={require("../images/RESPITECAREWARM.jpg")} alt="" /> */}
      <img src={require("../images/RESPITECARE.jpg")} alt="" />
      {/* <img src={require("../images/alvaro.JPG")} alt="" /> */}
      <img
        className="md:block hidden"
        src={require("../images/NIGHTCARE.jpg")}
        // src={require("../images/NIGHTCAREWARM.jpg")}
        alt=""
      />
      <div className="grid grid-flow-row gap-6 text-start">
        <div className="text-2xl">
          RESPITE CARE AND CARE AFTER HOSPITAL DISCHARGE
        </div>
        <div className="text-lg">
          Family caregivers should have time for themselves while their loved
          one is looked after in a safe, supportive environment. So, while
          family caregivers take a break to recharge and relax, St Agatha Care
          will provide a carer for your loved one at home. This gives you the
          chance to recuperate and support your own health and wellbeing while
          allowing your loved one to enjoy the excellent care and company of
          another caregiver.
        </div>
      </div>
      <img
        className="md:hidden"
        src={require("../images/NIGHTCARE.jpg")}
        // src={require("../images/NIGHTCAREWARM.jpg")}
        alt=""
      />
      <div className="grid grid-flow-row gap-6 text-start">
        <div className="text-2xl">NIGHT CARE</div>
        <div className="text-lg">
          A dedicated night-time carer can help with any necessary support
          throughout the night, offering you or your loved one reassurance and
          peace of mind while staying in the comfort of home.
        </div>
        <div className="text-lg">
          As with all of our services, we adapt to your individual needs,
          helping with bathroom visits, monitoring, personal care and any other
          needs which may arise, or simply providing reassurance and confidence
          that someone is present in case of emergency.
        </div>
      </div>
    </div>
  );
};
export default Care2;
