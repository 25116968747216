import React from "react";
import Care1 from "../components/Care1";
import Care2 from "../components/Care2";
import Header from "../components/Header";

const Services = () => {
  return (
    <>
      <Header underline={"services"} />
      <div className="text-3xl p-8">OUR SERVICES</div>
      <Care1 />
      <Care2 />
    </>
  );
};

export default Services;
