import React from "react";
import SaraLourdes from "../components/SaraLourdes";
import Header from "../components/Header";

const Team = () => {
  return (
    <>
      <Header underline={"team"} />
      <div className="text-3xl p-8">OUR TEAM</div>
      <SaraLourdes />
    </>
  );
};

export default Team;
