import React from "react";

const Footer = () => {
  return (
    <>
    <div className = "sm:block hidden">
      <div className="grid grid-flow-col p-7">
        <img
          src={require("../images/STAC_LOGO_HORIZONTAL_WHITE.png")}
          className="w-[350px] sm:block hidden"
          alt=""
        />
        <div className="grid grid-flow-col gap-4 text-lg items-center">
          <a href="/" className="underline underline-offset-4">
            Home
          </a>
          <a href="/recruitment" className="underline underline-offset-4">
            Recruitment Services
          </a>
          <a href="/services" className="underline underline-offset-4">
            Home Care
          </a>
          <a href="/Values" className="underline underline-offset-4">
            {/* Team */}
            Values
          </a>
          <a href="/team" className="underline underline-offset-4">
            {/* Team */}
            About
          </a>
          <a href="/contact" className="underline underline-offset-4">
            Contact
          </a>
        </div>
      </div>
      </div>
    </>
  );
};
export default Footer;
