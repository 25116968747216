import React from "react";

const HomeBrief = () => {
  return (
    <div className="sm:grid grid-cols-2 lg:gap-12 gap-8 lg:p-12 p-8 bg-white">
      <img
        className="sm:pb-0 pb-6"
        src={require("../images/WOMAN_CROP.jpg")}
        // src={require("../images/WOMAN_CROPWARM.jpg")}
        alt=""
      />
      <div className="grid grid-flow-row text-start gap-2 text-green">
        {/* <div className="text-3xl">YOUR WELL-BEING IS OUR PRIORITY</div> */}
        <div className="text-3xl">BESPOKE SENIOR CARE AT HOME</div>
        <div className="text-xl">
          We understand how challenging and isolating it can be when your loved ones
          need additional support to maintain their quality of life.
          St Agatha Care provides a dedicated, personal service for people seeking
          reliable, compasionate care in the comfort of their own home,
          ranging from companionship to complex needs.

          {/* We understand how challenging and isolating it can
          be when your loved ones need additional support in their lives.
          St Agatha Care provides a dedicated, personal service for people seeking
          reliable, compasionate care in the comfort of their own home,
          ranging from companionship to complex needs. */}

          {/*           
          St Agatha Care helps
          families by connecting people needing care with people with vocations
          to provide loving care, by offering personal care for them in the privacy
          of their own home as an affordable alternative to a residential or nursing home. */}
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            // window.location.href = "/Values";
            // window.location.href = "/services"
            window.location.href = "/Values"
          }}
          className="w-40 h-16 bg-[#48596c] text-white"
        >
          Learn more
        </button>
      </div>
    </div>
  );
};
export default HomeBrief;
