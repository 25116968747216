import React from "react";



import LiveInCareDetailBlurb from "../components/LiveInCareDetailBlurb";
import Header from "../components/Header";

const LiveInCareDetail = () => {
  return (
    <>
      <Header underline={"services"} />
      <div className="text-3xl pt-14">LIVE-IN CARE</div>
      {/* Print extra line to move text lower and be consistent with VisitingCareDetailBlurb */}
      <LiveInCareDetailBlurb />
    </>

  );
};

export default LiveInCareDetail;
