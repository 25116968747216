import React from "react";
import Header from "../components/Header";

function Contact() {
  return (
    <>
      <div className="h-[66vh]">
        <Header underline={"contact"} />
        <img
          className="absolute opacity-[0.40] -z-50 h-[66vh] w-full top-0 object-cover"
          // src={require("../images/CONTACTUS_CROPWARM.jpg")}
          src={require("../images/CONTACTUS_CROP.jpg")}
          alt=""
        />
        <div className="pt-14 text-3xl">CONTACT US</div>
      </div>
      <div className="grid grid-flow-row p-8 gap-6 bg-white text-green text-lg m-auto md:px-16 lg:px-32 lg:text-xl">
        <div>
          We are here for you. We will talk you through the service we offer and
          answer any questions you may have.
        </div>
        <div className="text-2xl">0207 884 0545</div>
        <div className="text-xl">enquiries@stagathacare.co.uk</div>
      </div>
    </>
  );
}

export default Contact;
