import React from "react";

const CarersTestimony = () => {
  return (
    <div className="sm:grid lg:gap-12 gap-8 lg:p-12 p-8 bg-green" >
      <div className="bg-white text-green pb-10 p-8 xl:px-12 ">
        {/* <div className="bg-white text-green pb-10 p-8 xl:px-12 "></div> */}
        {/* <div className="sm:grid grid-cols-2 sm:gap-6 lg:gap-12">
        <img className="h-64" src={require("../images/carers2.JPG")} alt="" />
        <img
          className="w-5/6 rounded-lg m-auto sm:mb-0 mb-6"
          src={require("../images/carers1.JPG")}
          alt=""
        />
        <div className="text-lg m-auto">
          St Agatha Care is supported by an excellent team of carers who have
          demonstrated their extraordinary skills and experience while literally
          changing people's lives.
        </div>

        HERE div className="sm:grid grid-cols-2 lg:gap-12 gap-8 lg:p-12 p-8 bg-green">
      
HERE?      <div className="grid grid-flow-row text-start gap-2 text-white">
        <div className="text-3xl">OUR SERVICES</div>

        div className="grid grid-flow-row text-start gap-2 text-green">
        <div className="text-3xl">YOUR WELL-BEING IS OUR PRIORITY</div>

      </div> */}
        <div className="text-3xl pb-8 ">
          {/* Here is a story of how carers of St Agatha Care have turned around a */}
          MARY'S STORY: HOW LIVE-IN CARE TURNED AROUND A PATIENT'S JOURNEY
        </div>
        <div className="sm:grid sm:grid-cols-3 gap-8 italic">
          <div className="pb-6">
            “During what should have a been a short hospital stay, my mother Mary
            was unable to leave for 111 days due to various hospital-acquired
            infections. A new diagnosis of Parkinsonism - a debilitating and
            painful disease, in addition to the dementia she already had, left her
            immobile, non-communicative and with a contorted body that required 24
            hour care.
            <br />
            <br />
            In a short space of time, our family had to adjust to an entirely new
            panorama with all the emotional distress that brought and to look for
            appropriate care. A nursing home was recommended as the only practical
            solution.
          </div>
          <div className="pb-6">
            Yet this didn't sit very well with us; we simply wanted to bring her
            home. Through a chance conversation with Sara at St. Agatha Care, we
            found an alternative solution that met the holistic needs of our
            family. Sara moved with speed and alacrity to find a team of
            experienced and available live-in carers.
            <br />
            <br />
            My mother returned to her own home, and in no time at all improved
            immeasurably. Through attention to her personal well-being and
            nutrition, my mother continues to improve and has become visibly
            happier.
          </div>
          <div>
            My mother is very alert to the familiarity of her home environment:
            pictures, décor, smells, sounds, and a steady stream of friends and
            family are able to pay regular visits. Her carers, Myrna and Amy have
            shown us just what care in the home can do using innovative ideas and
            their considerable experience with the elderly to bring about these
            improvements.
            <br />
            <br />
            These last few months have certainly had their challenges, and I am
            full of praise for the help that St. Agatha Care has been able to give
            us by connecting us with those who have a vocation to provide loving
            care in a home setting.”
          </div>
        </div>
      </div>
    </div >
  );
};
export default CarersTestimony;
