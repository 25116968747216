import React from "react";

const VisitingCareDetailBlurb = () => {
  return (
    //<div className="grid md:grid-cols-2 grid-cols-1 lg:p-12 p-8 lg:gap-12 gap-8">

    <>
      <div className="">
        {/* <div className="h-[66vh]"> */}
        <img
          className="absolute opacity-[0.40] -z-50 h-[66vh] w-full top-0 object-cover"
          src={require("../images/DAYTIMECARE.jpeg")}
          alt=""
        />
        <div className="grid grid-flow-row gap-6 text-start"></div>
        {/* <div className="p-12 py-59"> */}
        <div className="p-12 ">
          <div className="pt-5 text-lg text-start">
            <br />
            <br />
            St Agatha Care will personalise each visiting care plan to meet individual needs.
            Typical support includes:
          </div>
          <ul className="text-lg text-left list-disc px-12 pt-6" >
            <li>Support to start the day and personal care, such as: getting up, showering,
              dressing, shaving and oral hygiene</li>
            <li>Prompting to take medication or ordering and collecting prescriptions</li>
            {/* <br/> */}
            <li>Administering medication </li>
            <li> Mealtime assistance and food preparation</li>
            <li> Support running errands and shopping</li>
            <li> Companionship</li>
            <li> Light housework</li>
            <li> Domestic administration</li>
            <li> Assistance maintaining regular communication with friends and family</li>
            <li> Social activities support, such as going out for a walk, shopping trips, hobbies,
              visiting friends and family, attending church, club, entertainment shows and
              even holidays or other activities</li>
            <li> Monitoring wellbeing and health</li>
            <li> Accompanying to medical appointments</li>
            <li>Continence care and assistance getting to the toilet </li>
            <li> Help getting ready for bed</li>

          </ul>
        </div>
      </div>
    </>
  );
};
export default VisitingCareDetailBlurb;
