import React from "react";

const ValuesBlurb = () => {
  return (
    // <div className="sm:grid grid-cols-2 lg:gap-12 gap-8 lg:p-12 p-8 bg-white">

    <div className="grid grid-flow grid-cols-1 sm:grid-cols-2 grid-cols-flow gap-y-8 gap-x-32 bg-white text-green py-20 text-justify xl:text-xl lg:px-20 px-8 text-lg">
      {/* <div className="grid grid-flow-col grid-cols-2 flex flex-wrap  gap-y-8 gap-x-32 bg-white text-green py-20 text-justify xl:text-xl lg:px-20 px-8 text-lg"> */}
      <div className="col-start-1 col-end-2">

        {/* St Agatha Care recruits for individuals and institutions
        in Healthcare and Social Care. We specialise in providing high-quality, */}

        St Agatha Care provides trained Health and Care assistants to institutions and
        individuals. We specialise in providing high-quality, well-trained, and
        reliable Healthcare Assistants (HCA) and support workers
        on a temporary and permanent basis, offering a service tailored to your needs.
        <br />
        <br />
        We do the hard work for you to make the recruitment process effective and efficient,
        understanding your requirements and building long-term relationships.
        <br />
      </div>
      Each skilled care professional undergoes rigorous screening, including
      an interview process and relevant checks such as DBS, qualifications, experience,
      right to work in the UK and professional references. After this thorough
      recruitment process, each carer we put forward stands out for
      their kindness, politeness, respectfulness and natural, caring human touch.
      <br />
      <br />
      Achieving the highest standard of service is at the heart of our approach.
    </div>

  );
};
export default ValuesBlurb;
