import React from "react";

const SaraLourdes = () => {
  return (
    <>
      <div className="sm:flex p-6">
        <div className="sm:w-1/2 lg:pl-1.5 lg:pr-8 md:pl-1.5 md:pr-7 sm:pl-5 sm:pr-4 text-2xl text-left">
          {/* <div className="bg-white sm:flex p-8 ">
          <div className="sm:w-1/2 lg:px-10 md:px-7 sm:px-2 text-2xl text-justify bg-white text-green"> */}

          <img
            className="sm:pb-0 pb-6 scale-75 sm:hidden"
            src={require("../images/saracolour.jpg")}
            alt=""
          />

          <div className="py-8">SARA CASTRO</div>
          Sara is passionate about helping others. Working in recruitment
          and as a project manager in various industries, she has developed
          a customer-focus assisting people. She also understands caring for
          family is a big responsibility, which in some circumstances is
          better done by a professional without disrupting family bonds.
          <br />
          <br />
          Sara knows that the successful delivery of care begins by creating
          a good environment for the carers. Sara is meticulous about the staff
          and carers she brings into St Agatha Care and firmly believes that
          the happiness of the carers is directly linked to the well-being
          of the clients for whom they care. This is why St Agatha Care is
          embedded with a culture of empathy, communication and strong
          relationships between Sara, the team of carers and the clients.

        </div>

        <img
          className="sm:pb-0 pb-6 w-1/2 scale-75 object-top hidden sm:block"
          src={require("../images/saracolour.jpg")}
          alt=""
        />

      </div>
      <div className="bg-white sm:flex p-8">
        <img
          className="sm:pb-0 pb-6 w-1/2 scale-75 md:hidden"
          // src={require("../images/lourdescolour.jpg")}
          src={require("../images/Jailie.jpg")}
          alt=""
        />
        <img
          className="sm:pb-0 pb-6 w-1/2 scale-75 hidden md:block"
          src={require("../images/Jailie.jpg")}
          alt=""
        />
        {/* <div className="w-1/6" > </div> */}
        {/* new Sara <div className="sm:w-1/2 lg:pl-1.5 lg:pr-2 md:pl-1.5 md:pr-2 sm:pl-5 sm:pr-4 text-2xl text-left"> */}
        {/* previous Jailie <div className="md:w-1/2 lg:px-20 md:px-7 sm:px-2 text-2xl text-justify bg-white text-green"> */}

        <div className="sm:flex p-6">
          <div className=" lg:pl-1.5 lg:pr-3 md:pl-1.5 md:pr-3 sm:pl-5 sm:pr-4 text-2xl text-left bg-white text-green">
            {/* <div className="pb-6">JAILIE ENGUIO</div> */}
            <div className="py-8">JAILIE ENGUIO</div>
            <div>St Agatha Care is supported by an excellent team of carers, led by
              Jailie.
              <br />
              <br />
              Jailie has held a number of roles in the health and social care
              sector for over twenty years in the UK and the Philippines. As a
              Care Home Manager, she has been a champion of clients' dignity
              and safety, ensuring high levels of care are delivered and maintained
              by developing robust induction and training programmes. A dedicated,
              enthusiastic manager, Jailie has earned a Bachelor of Science degree
              in Respiratory Therapy in the Philippines. She completed her QCF Level 5 Leadership
              and Management Diploma in Health and Social Care in London and is studying for her
              Leaderhship and Management Diploma level 7.
              <br />
              <br />
              Jailie ensures that all carers' training and development
              needs are up to date and met in line with St Agatha Care requirements.

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SaraLourdes;
