import React from "react";

const LiveInCareDetailBlurb = () => {
  return (

    // // CHECK
    // <div className="grid grid-flow-row gap-6 text-start"></div>
    // {/* <div className="p-12 py-59"> */}
    // <div className="p-12 ">
    //   <div className="pt-20 text-lg text-start">

    //     St Agatha Care offers different levels of personalised care and a
    //     variety of services to match individual requirements in the privacy of
    //     your home, which can be provided on an hourly basis. Some clients may only
    //     need limited assistance, while others may require a higher degree of
    //     help during their daily activities to ensure they continue to enjoy an
    //     excellent quality of life while remaining as independent as possible.
    //     Typical support includes:
    //   </div>
    //   <ul className="text-lg text-left list-disc px-12 pt-6" >
    //     <li>Support to start t
    // CHECK

    <div>
      <img
        className="absolute opacity-[0.40] -z-50 h-[66vh] w-full top-0 object-cover"
        src={require("../images/LIVEIN_CROP2.jpeg")}
        alt=""
      />
      <div className="grid grid-flow-row gap-6 text-start"></div>
      <div className="p-12">
        <div className="pt-5 text-lg text-start">
          <br />
          <br />
          St Agatha Care will personalise each live-in care plan to meet individual needs.
          Typical support includes:
        </div>

        {/* <div className="text-2xl"></div> */}
        {/* <ul className="text-lg text-left list-disc p-12" > */}
        <ul className="text-lg text-left list-disc px-12 pt-6 " >

          <li>Support to start the day and personal care, such as: getting up, showering,
            dressing, shaving and oral hygiene</li>
          <li>Prompting to take medication or ordering and collecting prescriptions</li>
          <li>Administering medication</li>
          <li>Mealtime assistance and food preparation</li>
          <li>Support running errands and shopping</li>
          <li>Companionship</li>
          <li>Light housework</li>
          <li>Domestic administration</li>
          <li>Assistance maintaining regular communication with friends and family</li>
          <li>Light housework</li>
          <li>Domestic administration</li>
          <li>Companionship</li>
          <li>Social activities support, such as going out for a walk, shopping trips, hobbies, visiting friends and family, attending church, club, entertainment shows and even holidays or other activities</li>
          <li>Monitoring wellbeing and health</li>
          <li>Accompanying to medical appointments</li>
          <li>Continence care and assistance getting to the toilet</li>
          <li>Help getting ready for bed</li>
        </ul>
        <div className="text-lg">
        </div>

      </div>
      {/* <button className="w-40 h-16 bg-white text-[#48596c]">
          Learn more
        </button> */}
    </div>

  );
};
export default LiveInCareDetailBlurb;
